<div class="container">
	<div class="grid" style="margin: 0px 40px 0px 20px">
		<div class="col-12 bg-blue-700 text-white font-bold border-round flex justify-content-center text-3xl">
			Opciones visualización de los datos
		</div>

		<div class="col-1 col-offset-3 flex justify-content-center mt-2">
			<a (click)="graficosPorImporte()" class="OCM-button">Por importe</a>
		</div>

		<div class="col-1 col-offset-1 flex justify-content-center mt-2">
			<a (click)="graficosPorProcedimiento()" class="OCM-button">Por procedimiento</a>
		</div>

		<div class="col-1 col-offset-1 flex justify-content-center mt-2">
			<a (click)="graficosPorResultcode()" class="OCM-button">Resultado licitación</a>
		</div>
	</div>

	<div class="grid">
		<div class="col-1 col-offset-3 flex justify-content-center mt-2">
			<a (click)="graficosPorTypecode()" class="OCM-button">Tipología contratos</a>
		</div>

		<div class="col-1 col-offset-1 flex justify-content-center mt-2">
			<a (click)="graficosPorSubTypeCode()" class="OCM-button">Subtipo de contrato</a>
		</div>

		<div class="col-1 col-offset-1 flex justify-content-center mt-2">
			<a (click)="graficosPorUrgencyCode()" class="OCM-button">Tipo de tramitación</a>
		</div>
	</div>

</div>