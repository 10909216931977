<div class="OCM-navbarFooter">
	<div class="OCM-navbarFooter--brand">
		<a
			href="https://ocmjerez.org"
			class="OCM-navbarFooter--brand--logo"
			target="_blank"
			rel="nofollow"
		></a>
		<span class="OCM-navbarFooter--brand--text">OCM Jerez. Ver 1.05.05</span>
	</div>

	<div class="OCM-navbarFooter--rrss">
		<a
			href="https://facebook.com/OcmJerez/"
			class="logoFacebook"
			target="_blank"
			rel="nofollow"
		></a>
		<a href="https://twitter.com/ocmjerez" class="logoTwitter" target="_blank" rel="nofollow"></a>
	</div>

	<div class="OCM-navbarFooter--mail">
		<span>Email:&nbsp; </span>
		<a href="mailto:info@ocmjerez.org"> info@ocmjerez.org</a>
	</div>
</div>
