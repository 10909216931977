<div class="container">
	<div class="container__profile">
		<img src="../../../assets/img/IconoOCM.png" alt="ocm" />
		<div class="container__profile__text">
			<p>Los datos estan extraidos de la Plataforma de contratación del Estado.</p>
			<a href="https://bit.ly/3ggbyO5">Pagina de datos abiertos</a>
			<p>
				Se pueden observar problemas en los datos, por ejemplo: licitaciones repetidas, diferentes razones
				sociales para un mismo CIF y falta de cantidades en las adjudicaciones.
			</p>
		</div>
	</div>
</div>

<div style="margin: 50px; height: 350px">
	<div class="grid">
		<div class="col-12 bg-blue-700 text-white font-bold border-round flex justify-content-center text-3xl">
			Opciones para filtrar los datos</div>
	</div>

	<div class="grid">
		<div class="col-3 border-solid flex justify-content-center text-base">Selecciona fecha de inicio y final </div>
		<div class="col-3 border-solid flex justify-content-center text-base">Selecciona un ente de la lista </div>
		<div class="col-2 border-solid flex justify-content-center text-base">Selecciona procedimiento de la lista
		</div>
		<div class="col-2 border-solid flex justify-content-center text-base">Selecciona tipo de la lista </div>
		<div class="col-2 border-solid flex justify-content-center text-base">Introduce cantidades. 0 = todos </div>
	</div>

	<div class="grid">
		<div class="col-3 flex justify-content-center mt-2">
			<dp-date-picker #datePickerStart (onChange)="changeDatePickerStart($event)" theme="dp-material"
				[config]="config"></dp-date-picker>
			<dp-date-picker #datePickerEnd (onChange)="changeDatePickerEnd($event)" theme="dp-material"
				[config]="config">
			</dp-date-picker>
		</div>

		<div class="col-3  mt-2">
			<div>
				<ul class="list-group">
					<li class="list-group-item" *ngFor="let item of entesList">
						<input type="radio" [(ngModel)]="radioSelected" name="list_name" value="{{item.value}}"
							(change)="onItemChange()" />
						{{item.label}}

					</li>
				</ul>
			</div>
		</div>

		<div class="col-2  mt-2">
			<div>
				<ul class="list-group">

					<li class="list-group-item" *ngFor="let item of typeProcedureListChecked">
						<input type="checkbox" [value]="item.id" [checked]="item.checked" [disabled]="item.disabled"
							(change)="changeProcedure(item, $any($event.target).checked)" />
						{{item.value}}

					</li>

				</ul>
			</div>
		</div>

		<div class="col-2  mt-2">
			<div>
				<ul class="list-group">

					<li class="list-group-item" *ngFor="let item of typeCodeListChecked">
						<input type="checkbox" [value]="item.id" [checked]="item.checked" [disabled]="item.disabled"
							(change)="changeType(item, $any($event.target).checked)" />
						{{item.value}}

					</li>

				</ul>
			</div>
		</div>


		<div class="col-2 mt-2">
			<div>
				<label for="desde">Desde:</label><br>
				<input type="number" [(ngModel)]="rangeInit" name="desde"><br>
				<label for="hasta">Hasta:</label><br>
				<input type="number" [(ngModel)]="rangeEnd" name="hasta">
			</div>
		</div>



	</div>
</div>

<div style="margin: 100px">
	<div class="grid">
		<div class="col-12 bg-blue-700 text-white font-bold border-round flex justify-content-center text-3xl">
			Opciones visualización de los datos
		</div>

		<div class="col-4 flex justify-content-center mt-2">
			<a (click)="porLicitacion()" class="OCM-button">Por licitación</a>
		</div>

		<div class="col-4 flex justify-content-center mt-2">
			<a (click)="porAdjudicatario()" class="OCM-button">Por empresa adjudicataria</a>
		</div>

		<div class="col-4 flex justify-content-center mt-2">
			<a (click)="graficos()" class="OCM-button">Gráficos</a>
		</div>
	</div>
</div>