<div class="OCM-app-root">
	<div>
		<app-navbar></app-navbar>
	</div>
	<div>
		<router-outlet></router-outlet>
	</div>
	<div>
		<app-footer></app-footer>
	</div>
</div>