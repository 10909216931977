<div class="grid">
	<div class="col-12 bg-blue-700 text-white font-bold border-round flex justify-content-center text-3xl">
		{{ tituloPagina }}
	</div>

	<div class="col-6" style="height: 600px" *ngIf="options1">
		<ag-charts-angular style="height: 100%" [options]="options1"></ag-charts-angular>
	</div>

	<div class="col-6" style="height: 600px" *ngIf="options2">
		<ag-charts-angular style="height: 100%" [options]="options2"></ag-charts-angular>
	</div>

</div>