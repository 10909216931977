<nav class="OCM-navbarFooter">

	<div class="OCM-navbarFooter--brand">
		<a href="https://ocmjerez.org" class="OCM-navbarFooter--brand--logo" target="_blank" rel="nofollow"></a>
		<span class="OCM-navbarFooter--brand--text">Observatorio Ciudadano Municipal de Jerez</span>
	</div>

	<div class="OCM-navbarFooter--title">Contratos menores y licitaciones del Ayuntamiento de Jerez y sus entes
		dependientes </div>

	<div *ngIf="router.url === '/home'" class="OCM-navbarFooter--homeEmpty"></div>
	<div *ngIf="router.url !== '/home'" class="OCM-navbarFooter--home">
		<a routerLink="/" class="logoHome"></a>

	</div>

</nav>